import * as React from "react"
import styled from "styled-components"
import SquareImage from "../image/SquareImage"

const Container = styled.div`
  height: 27px;
  width: 27px;
`

const ImageRenderer = ({ value }) => {
  return (
    <Container>
      <SquareImage image={value} />
    </Container>
  )
}

export default ImageRenderer
