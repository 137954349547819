import * as React from "react"
import { FC, useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { approvePayment } from "../../../api/platform-admin/paymentsApi"
import {
  LoginContext,
  PrimaryButton,
  Spinner,
} from "@social-supermarket/social-supermarket-components"
import RecipientList from "../../orders/RecipientList"
import { getConfig } from "../../../../config"
import {
  paymentApi,
  PaymentType,
  RecipientType,
} from "@social-supermarket/social-supermarket-model"

const Container = styled.div``
const Info = styled.div`
  background-color: white;
  padding: 10px;
  margin-bottom: 10px;
`
const Buttons = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 10px;
`
const StyledButton = styled(PrimaryButton)`
  &&& {
    font-size: 0.8em;
    max-width: 250px;
    margin: 5px;
  }
`

interface Props {
  payment: PaymentType
  onApprove: () => void
}

const AdminPaymentPage: FC<Props> = ({ payment, onApprove }) => {
  const { token } = useContext(LoginContext)

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [recipients, setRecipients] = useState<RecipientType[]>(null)

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    try {
      setIsLoading(true)
      const recipients = await paymentApi.getRecipientsForPayment(
        getConfig().baseUrl,
        token,
        payment.paymentIntentId
      )
      setRecipients(recipients)
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }

  const handleApproveInvoice = async () => {
    try {
      setIsLoading(true)
      await approvePayment(token, payment.paymentIntentId)
      onApprove()
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Container>
      <Spinner isLoading={isLoading} />
      <Info>
        <b>Name:</b> {payment.name}
        <br />
        <b>Email:</b> {payment.email}
        <br />
        <b>Total:</b> £{payment.amount}
        <Buttons>
          <StyledButton onClick={() => {}}>Xero Lines</StyledButton>
          <StyledButton onClick={handleApproveInvoice}>Approve</StyledButton>
        </Buttons>
      </Info>
      <RecipientList recipients={recipients} autoSize />
    </Container>
  )
}

export default AdminPaymentPage
