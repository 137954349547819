import * as React from "react"
import styled from "styled-components"
import { FC, useContext, useEffect, useState } from "react"
import { LoginContext, Spinner } from "@social-supermarket/social-supermarket-components"
import { getCustomers } from "../../../api/platform-admin/customersApi"
import AdminCustomerList from "./AdminCustomerList"
import useQueryString from "../../../hooks/useQueryString"
import LinkButton from "../../../components/button/LinkButton"
import AdminCustomerPage from "./AdminCustomerPage"
import OrderType from "../../../model/OrderType"
import { CustomerType } from "@social-supermarket/social-supermarket-model"

const Container = styled.div`
  margin: 10px;
`

const BackButton = styled(LinkButton)`
  margin-bottom: 10px;
  margin-top: 10px;
`

interface Props {}

const AdminCustomersPage: FC<Props> = () => {
  const { token } = useContext(LoginContext)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [customers, setCustomers] = useState<CustomerType[]>(null)
  const [selectedCustomerId, setSelectedCustomerId] = useQueryString("customer", "")
  const [orderKey, setOrderKey] = useQueryString("orderKey", "")

  const selectedCustomer =
    selectedCustomerId && customers
      ? customers.find(customers => customers.ID == selectedCustomerId)
      : null

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    try {
      setIsLoading(true)
      setCustomers(await getCustomers(token))
    } catch (e) {
      console.log(e)
    } finally {
      setIsLoading(false)
    }
  }

  const handleBack = () => {
    if (orderKey) {
      setOrderKey("")
    } else if (selectedCustomerId) {
      setSelectedCustomerId("")
    }
  }

  const handleCustomerSelected = (customer: CustomerType) => {
    setSelectedCustomerId(customer.ID)
  }

  const handleOrderSelected = (order: OrderType) => {
    setOrderKey(order.key)
  }

  return (
    <Container>
      <Spinner isLoading={isLoading} />
      {selectedCustomer && <BackButton onClick={handleBack}>{"< Back"}</BackButton>}
      {customers && !selectedCustomer && (
        <AdminCustomerList customers={customers} onCustomerSelected={handleCustomerSelected} />
      )}
      {selectedCustomer && (
        <AdminCustomerPage
          customer={selectedCustomer}
          orderKey={orderKey}
          onOrderSelected={handleOrderSelected}
        />
      )}
    </Container>
  )
}

export default AdminCustomersPage
