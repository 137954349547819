import * as React from "react"
import styled from "styled-components"
import { colors } from "@social-supermarket/social-supermarket-model"

const Container = styled.div`
  height: 27px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Badge = styled.div`
  background-color: ${({ status }) => (status ? colors.green : colors.rouge)};
  color: white;

  padding: 6px 15px;
  line-height: 5px;
  margin: auto;
  border-radius: 15px;

  display: flex;
  justify-content: center;
  align-items: center;
`

const TrueFalseRenderer = ({ value }) => {
  return (
    <Container>
      <Badge status={value}>{value ? "Yes" : "No"}</Badge>
    </Container>
  )
}

export default TrueFalseRenderer
